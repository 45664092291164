<template>
  <div>
    <div style="padding: 20px; background: #FFFFFF">
      <div style="margin-bottom: 20px">
        <el-button type="primary" size="mini" @click="$router.push('/settings/project/cproject/add')">创建</el-button>
      </div>
      <el-table
        v-loading="loading"
        :data="projectArray"
        border
        style="width: 100%">
        <el-table-column
          type="index"
          align="center"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="pname"
          label="项目名称"
          width="150">
        </el-table-column>
        <el-table-column
          label="包含主题">
          <template scope="{ row }">
            <div v-if="row.msArray && row.msArray.length > 0">
              <span style="margin-right: 10px" :key="ms.id" v-for="ms in row.msArray">
                {{ ms.typeName }}
              </span>
            </div>
            <div v-else>
              未划分
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="主题上限"
          width="100">
          <template scope="{ row }">
            {{ row.maxMsCount === -1 ? '无限' : row.maxMsCount + '个' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          width="180">
        </el-table-column>
        <el-table-column
          align="center"
          label="新建方案"
          width="80">
          <template scope="{ row }">
            <el-switch
              @change="changeEnableAllowAddMsStatus(row)"
              v-model="row.allowAddMs"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="启用状态"
          width="80">
          <template scope="{ row }">
            <el-switch
              @change="changeEnableStatus(row)"
              v-model="row.isEnable"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="160">
          <template scope="{ row }">
            <el-button @click="$router.push('cproject/detail/' + row.id)" size="mini" type="primary" icon="el-icon-edit"></el-button>
            <el-button @click="delId = row.id; delDialog = true" size="mini" type="danger" icon="el-icon-delete"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="delDialog"
      width="30%">
      <span>确认删除该终端项目吗？删除后对应终端账号也会无法登录</span>
      <span slot="footer" class="dialog-footer">
    <el-button size="small" @click="delDialog = false">取 消</el-button>
    <el-button size="small" type="primary" @click="delDialog = false; deleteCP()">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import { listCP, setEnable, deleteCP, setAllowAdd } from '@/api/cProject'
export default {
  name: 'list',
  data () {
    return ({
      loading: true,
      projectArray: [],
      delDialog: false,
      delId: 0
    })
  },
  mounted () {
    this.fetchCPList()
  },
  methods: {
    async fetchCPList () {
      this.loading = true
      const res = await listCP()
      this.loading = false
      this.projectArray = res.data
    },
    indexMethod (index) {
      return index + 1
    },
    async changeEnableStatus (item) {
      this.loading = true
      const res = await setEnable(item)
      this.loading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
        item.isEnable = !item.isEnable
      }
    },
    async changeEnableAllowAddMsStatus (item) {
      this.loading = true
      const res = await setAllowAdd(item)
      this.loading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
        item.allowAddMs = !item.allowAddMs
      }
    },
    async deleteCP () {
      this.loading = true
      const res = await deleteCP({ id: this.delId })
      this.loading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.fetchCPList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
